<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Inquiry</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right">
                      <form class="search-form mr-form">
                        <input
                          type="text "
                          class="form-control"
                          v-model="searchText"
                          @input="viewInquiryList(1)"
                          placeholder="Search Here..."
                        />
                      </form>
                      <!-- create router link -->
                      <!-- <router-link to="CreateUser" class="btn btn-success
                      btn-small">Create</router-link>-->
                    </div>
                    <div class="d-flex">
                      <div>
                        <h3>
                          List of Inquiry
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h3>
                      </div>

                      <div class=" ml-3 ">
                        <b-button variant="success" @click="generateExcelForAllInquiry(page)"
                          >Download</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Name</th>
                          <th>Date of Inquire</th>
                          <th>Email Id</th>
                          <th>Phone No</th>
                          <th colspan="2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in inquiries" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>

                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.inquiryCreatedAt | moment("LL") }}</div>
                          </td>
                          <td>
                            <div>{{ item.email }}</div>
                          </td>
                          <td>
                            <div>{{ item.mobile }}</div>
                          </td>

                          <!-- v-repeat -->
                          <td>
                            <router-link
                              class="btn btn-primary"
                              :to="{ name: 'inquiryDetails', params: { id: item._id } }"
                              append
                            >
                              <!-- <font-awesome-icon :icon="['fas', 'edit']" /> -->
                              <b-icon-eye></b-icon-eye>
                            </router-link>
                          </td>
                        </tr>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md
                    font-weight-bold text-muted"
                    v-if="inquiries.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="inquiries.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewInquiryList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      inquiries: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: ""
    };
  },
  methods: {
    viewInquiryList(pageNo) {
      this.inquiries = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      apiservice.viewInquiryList(this.formData, data => {
        if (data.code === 200) {
          this.inquiries = data.data.result;

          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    generateExcelForAllInquiry() {
      apiservice.generateExcelForAllInquiry(this.formData, data => {
        apiservice.downloadExcel(data, "Inquiry_List");
      });
    }
  },
  created() {
    this.viewInquiryList(this.page);
  }
};
</script>

<style></style>
